<template>
  <v-menu
    v-if="item.CountFile === 0 && item.CountFolder === 0"
    bottom
    left
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        small
        v-bind="attrs"
        v-on="on"
      >
        mdi-plus
      </v-icon>
      <!-- <v-btn
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          small
        >
          mdi-plus
        </v-icon>
      </v-btn> -->
    </template>

    <v-list>
      <v-list-item @click="onAddSubitem('folder', item.ID, item.Level + 1)">
        <v-list-item-title><v-icon>mdi-folder</v-icon> {{ $t('folders.folder', locale) }}</v-list-item-title>
      </v-list-item>
      <v-list-item @click="onAddSubitem('file', item.ID, item.Level + 1)">
        <v-list-item-title><v-icon>mdi-file</v-icon> {{ $t('folders.file', locale) }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
  <!-- <v-btn
    v-else
    icon
    @click.stop.prevent="onAddSubitem(item.CountFolder > 0 ? 'folder' : 'file', item.ID, item.Level + 1)"
  >
    <v-icon small>mdi-plus</v-icon>
  </v-btn> -->
  <v-icon
    small
    v-else
    @click.stop.prevent="onAddSubitem(item.CountFolder > 0 ? 'folder' : 'file', item.ID, item.Level + 1)"
  >
    mdi-plus
  </v-icon>
</template>
<script>  
  export default {
    props: {
      item: {
        type: Object,
        required: true
      },
      onAddSubitem: {
        type: Function,
        required: false,
        default: null
      },
      locale: {
        type: String,
        required: true
      }
    },
  }
</script>